.table-pagination-parent-row {
  margin-top: 15px;
  padding: 0px 20px;
  margin-bottom: 10px;
  @media screen and (max-width: 1000px) {
    padding: 0px !important;
  }

  .result-per-page-input-number .ant-input-number-input {
    height: 20px;
  }

  .result-per-page-input-number:focus {
    border-color: grey;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 1px 1px 3px 0px rgb(145 145 145 / 50%);
  }

  .result-per-page-parent-div, .text {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0px;
    font-family: 'Open Sans', Arial;
    font-weight: 400;
    font-style: normal;
    color: rgba(35, 31, 32, 1);
    font-size: 12px;
    text-align: left;
    .ant-select {
      color: rgba(35, 31, 32, 1);
      font-size: 12px;
    }
    .ant-input-number-focused {
      box-shadow: 1px 1px 3px 0px rgb(145 145 145 / 50%);
    }
  }

  .result-per-page-input-number {
    svg {
      fill: black;
      font-size: 9px;
    }
    border-radius: 3px;
    height: 20px;
    box-shadow: rgb(#000000 / 35%) 0px 0px 4px 1px;
    border: none;
  }

  .result-per-page-input1 {
    width: 63px;
  }
  .go-to-page-input {
    width: 70px;
  }

  .table-pagination-arrow {
    text-align: center;
    margin: auto 10px;
    cursor: pointer;
  }

  .table-pagination-arrow-col {
    text-align: center;
    margin: auto 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.result-per-page-selector {
  width: 64px;
  .ant-select-selector {
    border: none !important;
    border-radius: 0px;
    box-shadow: none !important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 20px;
    padding: 0 11px;
  }
}

.result-per-page-dropdown {
  border: 1px solid var(--primary-color);
}

@primary-color: #50B6F2;