.production-manager-parent-row {
  .form-setup-table {
    .ant-table-column-sorter {
      padding-top: 24px !important;
    }
  }
  .heading-col {
    padding: 2px 25px 8px 12px;
    color: var(--primary-color);
    .heading-span {
      color: var(--primary-color);
    }
    .export-icon {
      cursor: pointer;
      height: 24px;
      width: 24px;
    }
  }
  .add-template-btn {
    border: 1px solid var(--primary-color);
    width: 100px;
    color: var(--primary-color);
    svg {
      fill: var(--primary-color);
    }
  }
}
.production-template-parent-row .heading-col .heading-template {
  line-height: 17px;
  letter-spacing: 0px;
  color: rgb(0, 0, 0);
  font-family: 'Open Sans', Arial;
  font-weight: 700;
  text-transform: uppercase;
}

.document-type-section {
  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0px;
  }
  .doc-type-number-form-item {
    margin-bottom: 10px;
    .ant-form-item-row {
      gap: 3px;
    }
  }
  .ant-col-24.ant-form-item-label {
    line-height: 1;
    white-space: nowrap;
    label {
      height: 16px !important;
      margin-bottom: 1px;
    }
  }
  .ant-form-item > .ant-form-item-row {
    flex-flow: column !important;
    .ant-form-item-label {
      text-align: start !important;
    }
  }
  .ant-form-item-has-error .ant-picker:not(.ant-picker-disabled) {
    border-radius: 0px;
    box-shadow: none;
    border: 1px solid #ff4d4f;
  }

  .ant-picker-input input {
    padding-left: 2px !important;
  }

  .ant-picker.ant-picker-disabled {
    background: #fff;
    border-color: transparent;
    cursor: text;
    .ant-picker-input::after {
      display: none;
    }
  }
  .flex-column {
    flex-flow: column !important;
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #383838;
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #383838;
      &::after {
        background-color: #383838;
      }
    }

    &::after {
      border: 1px solid #383838;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: white;
    border-color: rgba(38, 38, 38, 1);
  }
  .ant-checkbox-checked::after,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: rgba(38, 38, 38, 1);
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: rgba(38, 38, 38, 1);
  }

  .template-type {
    display: inline-block;
    .projects {
      margin-left: 25px;
    }
  }
  .save-btn {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    text-transform: uppercase;
    margin-top: 10px;
    font-size: 13px;
  }
  .field-button {
    width: 10%;
    color: var(--primary-color);
    border-color: var(--primary-color);
  }
  .project-radio {
    text-transform: capitalize !important;
  }
}

.text-document-template {
  line-height: 2.5715;
  padding-left: 10px;
  color: rgba(56, 56, 56, 1);
  font-family: 'Open Sans', Arial;
  font-weight: 400;
  font-size: 10px;
}
.text-complete-template {
  line-height: 16px;
  letter-spacing: 0px;
  color: rgb(56, 56, 56);
  font-family: 'Open Sans', Arial;
  font-weight: 600 !important;
  text-align: left;
  font-size: 10px !important;
  margin-left: 10px;
  font-style: normal;
  width: auto;
}
.div-text {
  padding-bottom: 10px;
  .checkbox-div {
    label {
      text-transform: capitalize;
      color: rgba(56, 56, 56, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 600;
      font-size: 13px;
    }
  }
}

.doc-type-table {
  .ant-table-column-title {
    .ant-input,
    .ant-select-selector {
      border-color: #c6c6c6;
    }
    .ant-select-selector,
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 25px;
    }
  }
}
.li-div {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 22px;
    transition: all 0.3s;
    font-size: 13px;
  }
}

.doc-template-parent-div {
  label {
    color: rgb(56, 56, 56);
    font-family: 'Open Sans', Arial;
    font-weight: 700;
    font-size: 11px;
    text-align: left;
  }
  .parent-col {
    .label-div {
      margin: 15px 0px 15px 0px;
      label {
        font-family: 'Open Sans', Arial;
        font-weight: 700;
        font-size: 13px !important;
        line-height: 16px;
        letter-spacing: 0px;
        color: rgba(32, 11, 70, 1);
        font-style: normal;
      }
    }
  }
  .add-plan-btn {
    width: 190px;
    height: 30px;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    margin: 10px 0px 40px 0px;
  }
  .border-less-input {
    color: rgb(56, 56, 56);
    font-family: 'Open Sans', Arial;
    font-weight: 400;
    font-size: 13px;
    text-align: left;
    box-shadow: none;
  }
  .field-row {
    margin: 15px 0px 30px 0px;
  }
  .select-options-parent-row {
    .add-option-btn {
      width: 94px;
      color: rgba(226, 28, 33, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 600;
      font-style: normal;
      font-size: 12px;
      height: 26px;
      margin-left: 40px;
      margin-bottom: 20px;
    }
  }
  .plan-parent-col {
    // display: flex;
    // justify-content: center;
    .plan-parent-div {
      height: 378px;
      padding: 10px 5px;
      border: 1px solid rgba(30, 64, 114, 0.57);
      border-radius: 3px;
      // max-width: 90%;
      // min-width: ;
      // width: 85%;
      .label-div {
        width: 90%;
        margin: 10px auto;
        label {
          font-size: 11px !important;
          color: rgb(56, 56, 56);
        }
      }
      .header {
        display: flex;
        justify-content: space-between;
        .name {
          line-height: 14px;
          letter-spacing: 0px;
          color: rgba(32, 11, 70, 1);
          font-family: 'Open Sans', Arial;
          font-weight: 700;
          font-style: normal;
          font-size: 12px;
          padding-left: 7%;
          padding-top: 8px;
        }
        .custom-icon {
          height: 15px;
          width: 15px;
        }
      }
    }
    .price-div {
      width: 100%;
      display: flex;
      justify-content: start;
      margin-bottom: 10px;
      vertical-align: middle;
      align-items: center;
      // gap: 10px;
      .r {
        padding-left: 15px;
        @media screen and (max-width: 1375px) {
          padding-left: 10px;
          padding-right: 0px;
        }
      }
      .text {
        text-align: right;
        line-height: 16px;
        letter-spacing: 0px;
        color: rgb(56, 56, 56);
        font-family: 'Open Sans', Arial;
        font-weight: 600 !important;
        font-size: 13px !important;
      }
      .ant-checkbox-wrapper {
        align-items: center;
        .ant-checkbox + span {
          // @media screen and (max-width: 1375px) {
          padding-right: 0px;
          top: 3px;
          position: relative;
          // }
        }
      }
      .ant-input-number,
      input {
        width: 130px;
        margin: 0px 5px;
      }
      label {
        padding-left: 5px !important;
      }
    }
    .add-btn-div {
      display: flex;
      justify-content: center;
    }
  }
  hr {
    border-top: 1px solid rgba(32, 11, 70, 1);
  }
}

@primary-color: #50B6F2;