.edit-application-parent {
  border: 1px solid rgba(246, 157, 1, 1);
  border-radius: 3px;
  padding: 15px 0px;
  margin-bottom: 30px;
  input.ant-input,
  .ant-input-number-input {
    height: 24px !important;
  }
  .ant-row {
    width: 100%;
  }
  .customer-type-col {
    line-height: 16px;
    letter-spacing: 0px;
    font-family: 'Open Sans', Arial;
    font-style: normal;
    font-size: 13px;
    text-align: left;
    div {
      color: rgb(56, 56, 56);
    }
    .label {
      font-weight: 600;
    }
    .sub-label {
      font-weight: 400;
    }
  }
  .name-col {
    border-bottom: 1px solid rgba(56, 56, 56, 1);
    line-height: 18px;
    letter-spacing: 0px;
    color: rgb(56, 56, 56);
    font-family: 'Open Sans', Arial;
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    text-align: center;
    padding-bottom: 10px;
  }
  .edit-btn-col {
    display: flex;
    justify-content: center;
    .ant-btn {
      width: 142px;
      height: 30px;
      background-color: var(--primary-color);
      color: white;
      border-radius: 2px;
      text-transform: uppercase;
      line-height: 16px;
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 600;
      font-style: normal;
      font-size: 13px;
      text-align: center;
      border: none;
    }
  }
  .heading-label-div {
    line-height: 17px;
    letter-spacing: 0px;
    color: var(--primary-color);
    font-family: 'Open Sans', Arial;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    text-align: left;
    border-bottom: 1px solid var(--primary-color);
    padding: 0px 0px 10px 2%;
    .sub-heading {
      font-weight: 400;
      font-style: normal;
      font-size: 12px;
    }
  }
  .contract-term-div {
    min-width: 240px;
    border: 1px solid rgba(198, 198, 198, 1);
    padding: 15px 10px;
    text-transform: capitalize;
    min-height: 175px;
    .description {
      line-height: 16px;
      letter-spacing: 0px;
      color: rgba(56, 56, 56, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      padding: 10px 10px 20px 10px;
    }
    .label-div {
      padding-bottom: 3px;
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(56, 56, 56, 1);
      label {
        line-height: 16px;
        letter-spacing: 0px;
        color: rgba(56, 56, 56, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 700;
        font-style: normal;
        font-size: 13px;
        text-align: left;
        padding-left: 10px;
      }
    }
    .contractCover-checkbox {
      flex-direction: row-reverse;
      width: 75%;
      justify-content: space-between;
      .ant-checkbox + span {
        width: 100%;
        line-height: 16px;
        letter-spacing: 0px;
        color: rgba(56, 56, 56, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
      }
    }
    .ant-checkbox-group {
      padding: 0px 20% 0px 1%;
      .ant-checkbox-wrapper {
        flex-direction: row-reverse;
        width: 100%;
        justify-content: space-between;
        .ant-checkbox + span {
          width: 100%;
          line-height: 16px;
          letter-spacing: 0px;
          color: rgba(56, 56, 56, 1);
          font-family: 'Open Sans', Arial;
          font-weight: 400;
          font-style: normal;
          font-size: 13px;
        }
      }
    }
    .ant-row {
      display: block;
    }
  }
  .plus-icon {
    width: 60px;
  }
  .section-a-connectivity-plans,
  .fibre-fixed-voice {
    border: 1px solid rgba(198, 198, 198, 1);
    border-radius: 3px;
    padding: 15px 0px 15px 20px;
    .half-height {
      height: 50%;
    }
    .full-height {
      height: 100%;
    }
    .border {
      border-bottom: 1px solid rgba(56, 56, 56, 1);
    }
    .description-col {
      padding-right: 15px;
      .description-div {
        padding-bottom: 3px;
        margin-bottom: 10px;
        label {
          line-height: 16px;
          letter-spacing: 0px;
          color: rgba(56, 56, 56, 1);
          font-family: 'Open Sans', Arial;
          font-weight: 700;
          font-style: normal;
          font-size: 13px;
          text-align: left;
          padding-left: 10px;
        }
      }
      .more-padding {
        padding-bottom: 40px;
      }
      .description {
        line-height: 17px;
        letter-spacing: 0px;
        color: rgba(56, 56, 56, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
        padding: 10px 20px 10px 10px;
        div {
          padding-bottom: 5px;
        }
      }
    }
    .plan-div {
      border-left: 1px solid rgba(198, 198, 198, 1);
      height: 100%;
      padding-top: 5px;
      .ant-checkbox-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: row-reverse;
        .ant-checkbox + span {
          line-height: 19px;
          letter-spacing: 0px;
          color: rgba(56, 56, 56, 1);
          font-family: 'Open Sans', Arial;
          font-weight: 400;
          font-style: normal;
          font-size: 13px;
          text-align: center;
          margin-right: 10px;
          .price {
            font-weight: 600;
          }
          .red {
            color: rgba(226, 15, 0, 1);
          }
          .limit {
            margin-bottom: 10px;
          }
        }
      }
    }
    .border-bottom {
      border-bottom: 1px solid rgba(198, 198, 198, 1);
    }
  }

  .fibre-fixed-voice {
    .ant-checkbox-wrapper {
      height: 100%;
      padding: 0px 15%;
      .ant-checkbox + span {
        margin-right: 0px !important;
        height: 70%;
      }
      .desc-parent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }
  }

  .other-value-added {
    padding: 10px 0px 10px 00px;
    .parent-row {
      min-height: 70px;
      .description-div {
        height: 100%;
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        padding: 0px 30px 0px 20px;
      }
      .ant-checkbox-wrapper {
        align-items: center;
        justify-content: space-around;

        .desc-parent {
          .price {
            height: 100%;
            display: flex;
            align-items: center;
            padding-top: 4px;
          }
        }
      }
    }
    .plan-div {
      padding: 5px 10px;
    }
    .first-row {
      min-height: 90px;
      .ant-checkbox-wrapper {
        height: 75%;
      }
      .heading {
        line-height: 16px;
        letter-spacing: 0px;
        color: rgba(56, 56, 56, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 700;
        font-style: normal;
        font-size: 13px;
        text-align: center;
        border-bottom: 1px solid rgba(56, 56, 56, 1);
        padding-bottom: 4px;
      }
    }
    .border-row {
      border-bottom: 1px solid rgba(198, 198, 198, 1);
    }
  }
  .value-added-services-note {
    margin: 20px 0px;
    text-align: center;
    line-height: 14px;
    letter-spacing: 0px;
    color: rgb(56, 56, 56);
    font-family: 'Open Sans', Arial;
    font-weight: 400;
    text-align: center;
    font-size: 12px;
    .note-sub-heading {
      font-style: italic;
    }
  }
  .lte-access-section {
    .head-col {
      padding: 0px 10px;
      .description-div {
        text-align: center;
        // line-height: 16px;
        letter-spacing: 0px;
        color: rgba(56, 56, 56, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 700;
        font-style: normal;
        font-size: 13px;
        text-align: center;
      }
    }
    .padding-top {
      padding-top: 8%;
    }
    .plan-div {
      min-height: 85px;
      height: 85%;
      padding-top: 10%;
    }
    .ant-checkbox-wrapper {
      .desc-parent {
        display: block;
      }
    }
  }
  .interim-lte-note {
    line-height: 17px;
    letter-spacing: 0px;
    color: rgba(56, 56, 56, 1);
    font-family: 'Open Sans', Arial;
    font-weight: 400;
    font-style: italic;
    font-size: 11px;
    text-align: left;
  }
  .customer-details-heading {
    margin-top: 40px;
  }
  .alternative-contact {
    margin-bottom: 0px !important;
  }
  .individual-app-inputs {
    margin-bottom: 30px;
    .ant-form-item-label > label {
      padding-left: 15%;
      width: 100%;
      line-height: 16px;
      letter-spacing: 0px;
      color: rgba(56, 56, 56, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 600;
      font-style: normal;
      font-size: 13px;
      text-align: left;
      &::after {
        content: '';
      }
    }
    .individual-note,
    .section-c-notes {
      line-height: 13px;
      letter-spacing: 0px;
      color: rgba(56, 56, 56, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 400;
      font-style: italic;
      font-size: 11px;
      text-align: left;
      padding-left: 2.5%;
    }
    .section-c-notes {
      margin-bottom: 6px;
    }
    .or-text {
      margin-left: 12%;
      line-height: 16px;
      letter-spacing: 0px;
      color: rgba(56, 56, 56, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 600;
      font-style: normal;
      font-size: 13px;
      text-align: left;
      text-decoration: underline;
    }
  }
  .sub-section-heading-col {
    padding: 0px 1% 10px 1%;
    div {
      padding-bottom: 5px;
      border-bottom: 1px solid rgba(32, 11, 70, 1);
      line-height: 16px;
      letter-spacing: 0px;
      color: rgb(32, 11, 70);
      font-family: 'Open Sans', Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 13px;
      text-align: center;
    }
  }
  .number-input-div {
    label {
      line-height: 16px;
      letter-spacing: 0px;
      color: rgb(56, 56, 56);
      font-family: 'Open Sans', Arial;
      font-weight: 600;
      font-style: normal;
      font-size: 13px;
      text-align: left;
      height: 18px;
    }
    .ant-input-number {
      margin: 0px 10px;
      min-width: 50%;
    }
  }
  .section-e {
    p {
      line-height: 22px;
      letter-spacing: 0px;
      color: rgba(56, 56, 56, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 500;
      font-style: normal;
      font-size: 13px;
      text-align: left;
    }
  }
  .first-name-div {
    .ant-col {
      max-width: 100%;
    }
    .ant-form-item-control-input {
      min-height: 24px;
    }
  }
  .first-name-div,
  .email-name-div {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    p,
    div {
      min-width: 180px;
    }
  }
  .email-name-div {
    margin-bottom: 30px;
    p {
      min-width: 120px;
    }
  }
  .terms-col {
    display: flex;
    align-items: center;
    font-size: 13px;
    div {
      margin-right: 20px;
    }
  }
  .bold {
    font-weight: 600;
  }
  .signature-section {
    .signature-col {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      .signature-div {
        border: 1px solid rgba(226, 226, 226, 1);
        border-radius: 2px;
        width: 80%;
        height: 150px;
      }
    }
    .bottom-text {
      line-height: 16px;
      letter-spacing: 0px;
      color: rgba(56, 56, 56, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 600;
      font-style: normal;
      font-size: 13px;
      text-align: center;
    }
    .date-col {
      .date-parent-div {
        text-align: left;
        .ant-picker {
          width: 200px;
          margin-bottom: 10px;
          .ant-picker-input > input {
            padding-left: 30px !important;
          }
          .ant-picker-input::after {
            background-image: url('../../../../../assets/down-arrow.svg') !important;
            background-size: 20px 14px;
            height: 14px;
            width: 22px;
          }
          .ant-picker-suffix {
            svg {
              fill: #aaaaaa;
              background-size: 20px 14px;
              height: 14px;
              width: 22px;
            }
          }
        }
      }
      p {
        text-align: left;
        padding-left: 30%;
      }
    }

    .container {
      width: 100%;
      height: 100%;
      top: 10%;
      left: 10%;
    }

    .sigContainer {
      width: 100%;
      height: 80%;
      margin: 0 auto;
      background-color: #fff;
    }

    .sigPad {
      width: 100%;
      height: 100%;
    }

    .clear-btn {
      width: 100%;
      height: 30px;
      background-color: #fff;
      border: none;
      border-top: 1px solid #e2e2e2;
      border-bottom: 1px solid #e2e2e2;
      color: #383838;
      &:hover {
        color: #383838;
      }
      cursor: pointer;
    }

    .sigImage {
      background-size: 100% 100%;
      width: 100%;
      height: 100%;
      background-color: white;
    }
  }
  .btns-col {
    border-top: 1px solid var(--primary-color);
    padding-top: 30px;
    .ant-btn {
      border-color: var(--primary-color);
      background-color: var(--primary-color);
      color: white;
      line-height: 16px;
      letter-spacing: 0px;
      font-family: 'Open Sans', Arial;
      font-weight: 600;
      font-style: normal;
      font-size: 13px;
      width: 142px;
      height: 30px;
      text-transform: uppercase;
    }
  }
  .ant-checkbox {
    font-size: 20px;
    &::after {
      border: 1px solid #383838;
    }
  }
  .ant-checkbox-inner {
    height: 20px;
    width: 20px;
    &::after {
      width: 7px;
      height: 12px;
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-inner {
    border-color: #383838;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #fff;
      border-color: #383838;
      &::after {
        position: absolute;
        display: table;
        border: 2px solid #383838;
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
        opacity: 1;
        transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        content: ' ';
      }
    }
  }
  .ant-radio.ant-radio-disabled,
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #383838;
      &::after {
        background-color: #383838;
      }
    }
  }
  .ant-radio-checked::after {
    border: 1px solid #383838;
  }
  .ant-radio-disabled .ant-radio-inner::after {
    background-color: #383838;
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 24px !important;
    padding: 0 11px;
  }
  .same-as-above {
    margin-bottom: 20px;
    line-height: 16px;
    color: rgba(56, 56, 56, 1);
    font-family: 'Open Sans', Arial;
    font-weight: 600;
    font-style: normal;
    font-size: 13px;
  }
  .ant-checkbox-wrapper {
    .desc-parent .price {
      line-height: 16px;
      letter-spacing: 0px;
      color: rgba(56, 56, 56, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
    }
  }
}

@primary-color: #50B6F2;