.notes-parent-row {
  border: 1px solid #f69d01;
  padding: 16px 12px 16px 12px;
  .heading-col {
    padding: 2px 25px 8px 12px;
    color: var(--primary-color);
    border-bottom: 1px solid rgba(85, 85, 85, 1);
  }
  .add-note-btn[disabled],
  .add-note-btn[disabled]:hover,
  .add-note-btn[disabled]:focus,
  .add-note-btn[disabled]:active {
    height: 24px !important;
  }
  colgroup,
  .ant-table-cell-scrollbar:not([rowspan]) {
    display: none;
  }
  .add-note-btn {
    border: 1px solid var(--primary-color);
    width: 84px;
    color: var(--primary-color);
    svg {
      fill: var(--primary-color);
    }
  }
  .ant-table-thead > tr th {
    border-bottom-color: #000000;
    padding: 12px 0px 4px 16px;
  }
  .ant-table-tbody > tr > td {
    padding: 8px 4px 8px 4px !important;
    border-bottom: 1px solid #e2e2e2;
  }
}

@primary-color: #50B6F2;