.pillar-section-parent-row {
  // background-color: aqua;
  margin: 30px 0px;
  .border-right {
    border-right: 1px solid rgba(226, 226, 226, 1);
  }
  label {
    letter-spacing: 0px;
    font-family: 'Open Sans', Arial;
    font-weight: 700;
    font-style: normal;
    // height: 13px;
    text-transform: uppercase;
    // padding-left: 10px;

    line-height: 14px;
    letter-spacing: 0px;
    color: rgba(35, 31, 32, 1);
    font-family: 'Open Sans', Arial;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    text-decoration: underline;
  }
  .status-select {
    width: 127px;
    height: 27px;
    .dot {
      height: 13px;
      width: 13px;
      border-radius: 50%;
      display: inline-block;
      border-color: rgba(209, 209, 209, 1);
      margin-right: 10px;
      margin-top: 4px;
    }
    .good {
      background-color: rgba(67, 160, 71, 1);
    }
    .medium {
      background-color: rgba(251, 140, 0, 1);
    }
    .poor {
      background-color: rgba(249, 51, 36, 1);
    }
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 24px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    height: 27px !important;
    padding-left: 11px !important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 11px !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 26px;
  }

  .ant-checkbox-wrapper {
    flex-direction: row-reverse;
    width: 95%;
    justify-content: space-between;
    &::after {
      content: none;
    }
    .label {
      line-height: 14px;
      letter-spacing: 0px;
      color: rgba(35, 31, 32, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 500;
      font-style: normal;
      font-size: 12px;
      display: inline-flex;
      gap: 20px;
      width: 100%;
      align-items: center;
      span {
        min-width: 50px;
      }
      .ant-input {
        height: 20px;
        // margin-top: 10px;
      }
    }
  }

  .ant-checkbox + span {
    padding-left: 0px;
    width: 90%;
  }
  .attach-parent-div {
    height: 16px;
    color: rgba(226, 28, 33, 1);
    svg {
      fill: rgba(226, 28, 33, 1);
    }
  }
  .pillar2-div {
    margin-bottom: 30px;
  }
  .ant-input-number {
    width: 40px;
    height: 20px;
    margin-left: 10px;
    line-height: 1px;
    .ant-input-number-input {
      height: 14px;
      padding: 0px 7px;
    }
  }
}

.status-select-dropdown {
  .ant-select-item .ant-select-item-option-content {
    line-height: 16px;
    letter-spacing: 0px;
    color: rgb(56, 56, 56);
    font-family: 'Open Sans', Arial;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    line-height: 1.5;
  }
  .dot {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    display: inline-block;
    border-color: rgba(209, 209, 209, 1);
    margin-right: 10px;
    margin-top: 4px;
  }
  .good {
    background-color: rgba(67, 160, 71, 1);
  }
  .medium {
    background-color: rgba(251, 140, 0, 1);
  }
  .poor {
    background-color: rgba(249, 51, 36, 1);
  }
}

.static-fields-row {
  margin-bottom: 20px;
  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 0px;
    label {
      height: 24px;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 24px !important;
  }
  .ant-input-number {
    width: 100%;
  }
  label {
    line-height: 13px;
    letter-spacing: 0px;
    color: rgb(35, 31, 32);
    font-family: 'Open Sans', Arial;
    font-weight: 700;
    font-style: normal;
    font-size: 11px;
    // height: 13px;
    text-transform: uppercase;
    // padding-left: 10px;
    min-width: 75px;
  }
  .ant-form-item-row {
    .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::before {
      content: none;
    }
  }
  .ant-form-item {
    margin-bottom: 0px;
    line-height: 14px;
    color: rgb(35, 31, 32);
    font-family: 'Open Sans', Arial;
    font-weight: 500;
    font-size: 12px;
    .ant-form-item-label {
      text-align: start;
    }
    .ant-switch {
      width: 45px;
      border-radius: 20px;
    }
    .ant-switch-checked {
      background: rgb(33, 150, 243);
      &:focus {
        box-shadow: none;
      }
    }
  }
  .count-div {
    padding-top: 8px;
    margin: 0px 5px;
    div {
      width: 30px;
      height: 16px;
      background-color: rgba(252, 233, 212, 1);
      border: 1px solid rgba(230, 81, 0, 1);
      border-radius: 19px;

      font-family: 'Open Sans', sans-serif, Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 11px;
      text-align: center;
      line-height: 13px;
      letter-spacing: 0px;
      color: rgba(230, 81, 0, 1);
      padding-top: 1px;
    }
  }
  .attach-parent-div {
    padding-top: 7px;
    button {
      width: 53px;
      height: 18px;
      border-radius: 2px;
      border: 1px solid rgba(226, 28, 33, 1);

      line-height: 13px;
      letter-spacing: 0px;
      color: rgba(226, 28, 33, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 600;
      font-style: normal;
      font-size: 11px;
      text-align: center;
    }
  }
  .apply-btn {
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color);
    letter-spacing: 0px;
    font-family: 'Open Sans', Arial;
    font-weight: 600;
    font-style: normal;
    font-size: 11px;
    text-align: center;
  }
}

.ant-modal-mask {
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.4); /* Adjust opacity as needed */
}

.outlines-confirm-modal {
  .ant-modal-body {
    width: max-content;
    background: white;
  }

  .ant-modal-confirm-btns {
    display: flex;
    justify-content: end;
    flex-direction: row-reverse;
    gap: 20px;
    .ant-btn-primary {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: white;
    }
  }
}

@primary-color: #50B6F2;