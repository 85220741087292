.doc-status-table,
.project-statuses-table {
  .ant-table-tbody > tr.ant-table-row:hover > td svg,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover svg {
    fill: var(--primary-color);
  }

  .completed-status-col {
    display: flex;
    align-items: flex-end;
    .edit-icon {
      width: 14px;
      height: 14px;
      fill: var(--primary-color);;
    }
    .disable-icon {
      cursor: not-allowed !important;
    }
  }

  .ant-checkbox {
    font-size: 20px;
    &::after {
      border: 1px solid #383838;
    }
  }
  .ant-checkbox-inner {
    height: 20px;
    width: 20px;
    &::after {
      width: 7px;
      height: 12px;
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-inner {
    border-color: #383838;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #fff;
      border-color: #383838;
      &::after {
        position: absolute;
        display: table;
        border: 2px solid #383838;
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
        opacity: 1;
        transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        content: " ";
      }
    }
  }
  .ant-radio.ant-radio-disabled,
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #383838;
      &::after {
        background-color: #383838;
      }
    }
  }
  .ant-radio-checked::after {
    border: 1px solid #383838;
  }
  .ant-radio-disabled .ant-radio-inner::after {
    background-color: #383838;
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }
}

.row-dragging {
  .ant-space {
    svg {
      width: 16px;
      height: 16px;
      font-size: 16px;
      fill: var(--primary-color);
    }
  }
}

@primary-color: #50B6F2;