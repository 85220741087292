.login-most-parent-div {
  width: 100vw;
  height: 100vh;
  .parent-row {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
  }

  .login-form-parent-div {
    background-color: white;
    width: 326px;
    min-height: 430px;
    text-align: center;
    border: 1px solid var(--primary-color);
    filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.5));
    border-radius: 3px;
    .logo-div {
      height: 100px;
      width: 130px;
      margin-top: 20px;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    .login-text {
      color: var(--primary-color);
      font-family: 'Nunito', Arial;
      font-weight: 800;
      font-size: 17px;
      margin-bottom: 35px;
      span {
        font-size: 12px;
        color: rgb(56, 56, 56);
        font-weight: 500;
        padding-top: 10px;
      }
    }
    .ant-input {
      width: 230px;
      font-size: 12px;
    }

    .ant-input::placeholder,
    input::placeholder {
      font-size: 12px !important;
      font-family: 'Open Sans';
      // opacity: 0.6;
      font-family: 'Open Sans', Arial;
      font-weight: 400 !important;
      line-height: 14px;
      color: #717171 !important;
      opacity: 1 !important;
    }
    .ant-input:focus,
    .ant-input-focused,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      border-color: none;
      box-shadow: none;
      border-right-width: 0px;
      outline: 0;
    }
    .email-input,
    .ant-input-affix-wrapper {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .ant-input-affix-wrapper,
    .ant-input-status-success,
    .email-input {
      border: none;
      border-bottom: 1px solid #a6a6a6;
    }
    .forgot-email-input {
      border: 1px solid #a6a6a6 !important;
    }
    .ant-form-item-explain {
      font-size: 11px;
    }
    .ant-form-item-explain-error {
      text-align: start !important;
    }
    .forgot-password-div {
      text-align: right;
      margin: 0px 0px 30px 0px;
      .link-text {
        text-decoration: underline;
        font-size: 10px;
        color: #717171;
        font-weight: normal;
      }
    }
    .login-btn {
      line-height: 17px;
      border: 0px solid var(--primary-color);
      width: 127px;
      height: 30px;
      text-transform: uppercase;
      border-radius: 19px;
      background-color: var(--primary-color);
      letter-spacing: 0px;
      color: white;
      font-family: 'Nunito', Arial;
      font-weight: 800;
      font-style: normal;
      font-size: 14px;
      text-align: center;
    }
  }
  .anticon svg {
    fill: #717171;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000000s ease-in-out 0s;
}

@primary-color: #50B6F2;