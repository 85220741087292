.attachments-parent-row {
  border: 1px solid #f69d01;
  padding: 16px 12px 16px 12px;
  .heading-col {
    padding: 2px 25px 8px 12px;
    color: var(--primary-color);
    border-bottom: 1px solid rgba(85, 85, 85, 1);
  }
  .send-email-btn[disabled],
  .send-email-btn[disabled]:hover,
  .send-email-btn[disabled]:focus,
  .send-email-btn[disabled]:active {
    height: 24px !important;
  }
  colgroup,
  .ant-table-cell-scrollbar:not([rowspan]) {
    display: none;
  }
  .send-email-btn {
    border: 1px solid var(--primary-color);
    width: 207px;
    color: var(--primary-color);
    align-items: center;
    display: flex;
    font-size: 12px;
    svg {
      fill: var(--primary-color);
      height: 20px;
      width: 20px;
    }
    span {
      padding-left: 8px;
    }
  }
  .ant-table-thead > tr th {
    border-bottom-color: #000000;
    padding: 12px 0px 4px 16px;
    line-height: 13px;
    letter-spacing: 0px;
    color: rgba(35,31,32,1);
    font-family: 'Open Sans',Arial;
    font-weight: 700;
    font-style: normal;
    font-size: 11px;
  }
  .ant-table-tbody > tr > td {
    padding: 8px 4px 8px 4px !important;
    border-bottom: 1px solid #e2e2e2;
  }
  .required-field-text {
    height: 12px;
    line-height: 12px;
    letter-spacing: 0px;
    color: rgb(56, 56, 56);
    font-family: 'Open Sans', Arial;
    font-weight: 500;
    font-style: italic;
    font-size: 10px;
    text-align: right;
    padding-right: 10px;
    padding-top: 4px;
  }
  .attach-label-row {
    .label {
      line-height: 13px;
      letter-spacing: 0px;
      color: rgb(56, 56, 56);
      font-family: 'Open Sans', Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 11px;
      display: flex;
      gap: 10px;
      .rotate-icon {
        transform-origin: 5px 12px;
      }
      .anticon-plus {
        svg {
          fill: var(--primary-color);
          width: 12px;
          height: 100%;
        }
      }
      .others {
        padding-left: 7px;
      }
    }
    .name-span {
      text-decoration: underline;
    }
    .del-icon {
      width: 13px;
      height: 13px;
    }
    svg {
      width: 19px;
      height: 15px;
      fill: var(--primary-color);
    }
    .attach-parent-div {
      padding-top: 0px;
    }
  }
}

@primary-color: #50B6F2;