body {
  margin: 0;
  font-family: 'Open Sans',Arial !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Open Sans', Arial, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.tooltip {
  position: relative; /* making the .tooltip span a container for the tooltip text */
  // border-bottom: 1px dashed #000; /* little indicater to indicate it's hoverable */
  &:before {
    // opacity: 0;
    transition: 0.3s opacity;
    content: attr(data-text); /* here's the magic */
    position: absolute;

    /* vertically center */
    // top: 50%;
    // transform: translateY(-50%);

    top: -45px;
    transform: translateX(-65%);

    /* move to right */
    left: 100%;
    margin-left: 0px; /* and add a small left margin */

    /* basic styles */
    // width: 100px;
    width: max-content;
    padding: 5px;
    border-radius: 7px;
    background: #fff;
    color: #000;
    text-align: center;
    border: 1px solid black;
    display: none; /* hide by default */
    z-index: 1;
    &:hover::before {
      display: block;
      opacity: 1;
    }
    font-size: 13px;
  }
  &::after {
    content: "";
    position: absolute;

    /* position tooltip correctly */
    left: 100%;
    margin-left: -5px;

    /* vertically center */
    top: 50%;
    transform: translateY(-50%);

    /* the arrow */
    // border: 10px solid #000;
    // border-color: transparent black transparent transparent;

    display: none;
    opacity: 0;
    transition: 0.3s;
  }
  &:hover::before,
  &:hover::after {
    display: block;
  }
  &:hover:after {
    opacity: 1;
  }
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.attach-select {
  width: 100%;
  .ant-select-selector {
    padding: 0px !important;
    min-height: 25px;
    margin-bottom: 5px;
  }
  .ant-select-arrow,
  .ant-select-clear {
    margin-top: -8px;
  }
}

.attach-dropdown {
  .ant-select-item-option-content {
    font-size: 13px;
    color: rgb(35, 31, 32);
    &:hover {
      color: #fff;
    }
  }
}

@primary-color: #50B6F2;