.users-manager-parent-row {
  .heading-col {
    padding: 2px 25px 8px 12px;
    color: var(--primary-color);
  }
  .add-user-btn {
    border: 1px solid var(--primary-color);
    // width: 90px;
    height: 30px;
    color: var(--primary-color);
    text-transform: uppercase;
    svg {
      fill: var(--primary-color);
    }
    .anticon {
      line-height: 1;
    }
  }
}
.texts {
  line-height: 12px;
  letter-spacing: 0px;
  color: rgba(113, 113, 113, 1);
  font-family: 'Open Sans', Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  text-align: center;
}
.text {
  font-weight: 400 !important;
  font-style: normal !important;
}

.users-manager-table {
  .ant-table-column-title {
    .ant-input,
    .ant-select-selector {
      border-color: #c6c6c6;
    }
    .ant-select-selector,
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 25px;
    }
  }
}

@primary-color: #50B6F2;