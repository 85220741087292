// .production-manager-parent-row {
//   .heading-col {
//     padding: 2px 25px 8px 12px;
//     color: var(--primary-color);
//   }
//   .add-user-btn {
//     border: 1px solid var(--primary-color);;
//     width: 100px;
//     color: var(--primary-color);
//     svg {
//       fill: var(--primary-color);;
//     }
//   }
// }
// .texts {
//   line-height: 12px;
//   letter-spacing: 0px;
//   color: rgba(113, 113, 113, 1);
//   font-family: "Open Sans", Arial;
//   font-weight: 400;
//   font-style: normal;
//   font-size: 10px;
//   text-align: center;
// }
// .text {
//   font-weight: 400 !important;
//   font-style: normal !important;
// }

// .status-selector {
//   min-width: 150px !important;
// }

// .doc-type-selector {
//   min-width: 230px !important;
//   max-width: max-content;
// }

.slots-date-picker {
  .ant-picker:not(.ant-picker-range) .ant-picker-input input {
    padding-left: 4px !important;
  }
}

@primary-color: #50B6F2;