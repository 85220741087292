.btnSubmit-cls:hover {
  cursor: pointer;
  color: white !important;
  background-color: #e40505 !important;
}

.model-logo-new-specs {
  width: 100%;
  text-align: center;
  padding: 20px;
  background: white;
  margin: auto;
}
.forgot-most-parent-div {
  width: 100vw;
  height: 100vh;
  .parent-row {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
  }
  .forgot-email-input {
    font-size: 13px;
  }
}
.forgot-password-parent-div {
  background-color: white;
  width: 326px;
  height: 430px;
  text-align: center;
  border: 1px solid var(--primary-color) !important;
  // box-shadow: 0 0 3px #204596;
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.5));
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  .logo-div {
    height: 100px;
    width: 130px;
    margin-top: 20px;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    color: #383838;
  }

  .ant-form-item-label > label {
    color: #383838;
    font-size: 13px;
  }

  .reset-form {
    .ant-form-item {
      margin-bottom: 10px;
    }

    .ant-col-24.ant-form-item-label,
    .ant-col-xl-24.ant-form-item-label {
      padding: 0px;
    }

    .ant-form-item-label > label {
      height: 32px;
      line-height: 13px;
      color: rgb(56, 56, 56);
      font-family: 'Open Sans', Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 11px;
    }

    .ant-input {
      width: 100% !important;
    }

    .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::before {
      content: '';
    }

    .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::after {
      display: inline-block;
      margin-right: 6px;
      color: #383838;
      font-size: 14px;
      font-family: sans-serif;
      line-height: 1;
      content: '*';
    }
  }

  .login-text {
    color: var(--primary-color);
    margin-bottom: 30px !important;
    font-weight: 700;
    p {
      margin-bottom: 1em;
      margin-top: 12px;
      font-size: 16px;
      font-family: 'Nunito', Arial;
    }
    .reset-span {
      padding: 0px 23px;
      line-height: 13px;
      letter-spacing: 0px;
      color: rgb(56, 56, 56);
      font-family: 'Open Sans', Arial;
      font-weight: 400 !important;
      font-style: normal;
      text-align: justify;
      span {
        padding-top: 10px !important;
        font-size: 11px !important;
      }
    }
    .span-div {
      padding: 0px 45px;
      color: rgb(56, 56, 56);
      font-weight: 400;
      line-height: 1;
      font-family: 'Open Sans', Arial;
      span {
        font-size: 12px;
        margin-bottom: 1.1em;
      }
    }
  }
  .reset-text-div {
    margin-bottom: 15px !important;
  }
  .ant-form-item-explain {
    padding-left: 8px !important;
  }

  .send-btn {
    // line-height: 17px;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 20px;
    width: 127px;
    height: 30px;
    text-transform: uppercase;
    filter: none;
    line-height: 17px;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    font-family: 'Nunito', Arial;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    text-align: center;
    touch-action: none;
    user-select: none;
    -webkit-user-drag: none;
  }
  .send-btn:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
  .send-btn:active {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
  }
  .send-btn:hover {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
  }

  .bottoom-p-text {
    color: rgba(56, 56, 56, 1);
    font-family: 'Open Sans', Arial;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    text-align: center;
    text-decoration: underline;
  }
}
.ant-form-item-explain-error {
  color: #ff4d4f;
  text-align: start;
}

@primary-color: #50B6F2;